var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        attrs: {
          visible: _vm.dialogVisible,
          width: "40%",
          "custom-class": "emails",
          center: "",
          top: "10vh",
          "append-to-body": "",
          "close-on-click-modal": false,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("p", { staticClass: "title" }, [_vm._v("Email Nomination")]),
      _c(
        "div",
        { staticClass: "email-block" },
        [
          _vm._l(_vm.emails, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c("el-input", {
                  attrs: { size: "small", placeholder: "email" },
                  model: {
                    value: item.email,
                    callback: function ($$v) {
                      _vm.$set(item, "email", $$v)
                    },
                    expression: "item.email",
                  },
                }),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      round: "",
                      size: "small",
                      disabled: _vm.emails.length === 1,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.removeEmail(index)
                      },
                    },
                  },
                  [_vm._v(" Remove ")]
                ),
              ],
              1
            )
          }),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "5px" },
              attrs: { round: "", size: "small" },
              on: { click: _vm.addEmail },
            },
            [_vm._v(" + Add more reciepient ")]
          ),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "180px" },
              attrs: { type: "primary", round: "" },
              on: { click: _vm.sendEmails },
            },
            [_vm._v(" " + _vm._s(_vm.$t("send")) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }